// Here you can add other styles

// # General
p {
  margin-bottom: unset;
}

// Form
.form-control:focus {
  box-shadow: none;
  border-color: var(--cui-input-focus-border-color, #b1b7c1);
}

.app_multi_select_toggle {
  border: 1px solid #b1b7c1;
  width: 100%;
  padding: 0.375rem 0.75rem;
  border-radius: 0.375rem;
  cursor: pointer;
  color: #b1b7c1;
}

.have_value {
  color: black;
}

.modal_custom_container.app_multi_select_modal {
  display: flex;
  flex-direction: column;
  height: fit-content;
  width: min(900px, 100%);
}

.app_multi_select_modal_title {
  font-size: 1.625rem;
  font-weight: 700;
  color: #345ae0;
  margin-bottom: 2rem;
  text-align: center;
}

.app_multi_select_accordion {
  overflow-y: auto;
}

.app_multi_select_accordion_option {
  display: flex;
  width: 100%;
}

.app_multi_select_accordion_item {
  width: 100%;
  margin-left: 1rem;
}

.app_multi_select_accordion_item_header {}

.app_multi_select_accordion_item_body {}

// # Details Page

// ## Button Group
.article_details_action_button_group {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 0.5rem;
  padding: 1rem;
}

// ## Author Details
.author_details_card {
  margin-bottom: 2.5rem;
  border-radius: 0.625rem;
}

.author_details_heading {
  font-size: 1rem;
  font-weight: 700;
  letter-spacing: 0.15rem;
  text-transform: uppercase;
  margin-bottom: 1.75rem;
}

.author_details_section_primary {
  margin-bottom: 1.5rem;
  display: flex;
  align-items: center;
  gap: 0.75rem;
}

.author_details_section_secondary {
  margin-bottom: 0.75rem;
}

.author_details_name {
  font-size: 1.875rem;
  font-weight: 600;
  line-height: 2rem;
}

.author_details_subheading {
  font-size: 1rem;
  font-weight: 700;
  margin-bottom: 0.5rem;
}

.author_details_role_value {
  border: 1px solid black;
  padding: 0.5rem 2.5rem;
  width: fit-content;
}

.author_details_row {
  margin-bottom: 1.875rem;
}

.author_details_link {
  color: #345ae0;
  font-size: 0.75rem;
  line-height: 1rem;
}

// ## Non Author Submission Detail
.non_author_submission_detail_header {
  display: flex;
  align-items: center;
}

.non_author_submission_detail_back_button {
  text-decoration: none;
  color: black;
  margin-right: 0.25rem;
}

.non_author_submission_detail_header_title {
  font-weight: 400;
}

.non_author_submission_detail_header_subtitle {
  font-weight: 700;
  font-size: 1.125rem;
  margin-bottom: 0.5rem;
}

// # Modal

// # Confirm Modal
.submission_confirm_modal_body_text {
  margin-bottom: 1rem;
}

.submission_confirm_modal_input_label {
  margin-bottom: 0.5rem;
  font-weight: 700;
}

.modal_custom_root {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1050;
}

.modal_custom_container {
  z-index: 1051;
  background: white;
  height: 600px;
  width: min(700px, 100%);
  filter: drop-shadow(0 10px 8px rgb(0 0 0 / 0.04)) drop-shadow(0 4px 3px rgb(0 0 0 / 0.1));
  border-radius: 30px;
  padding: 5rem 6rem;
}

.modal_custom_container.app_modal_approval {
  height: 400px;
  width: min(700px, 100%);
}

.modal_custom_overlay {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1050;
  width: 100%;
  height: 100%;
  background: grey;
  opacity: 0.4;
}

.modal_custom_approve_content_container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
}

.modal_custom_approve_content_title {
  font-size: 1.5rem;
  font-weight: 700;
  color: #345ae0;
  margin-bottom: 2rem;
}

.modal_custom_approve_content_subtitle {
  text-align: center;
  font-size: 1rem;
  margin-bottom: 2rem;
}

.modal_custom_approve_content_form {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 2rem;
  width: 100%;
}

.modal_custom_approve_content_form_label {
  font-size: 1rem;
  font-weight: 700;
  color: #b55700;
  margin-bottom: 1rem;
}

.modal_custom_approve_content_form_select {
  margin-bottom: 1rem;
}

.modal_custom_approve_content_button_group {
  display: flex;
  gap: 1rem;
  margin-top: 1rem;
}

// Discussion Board
.discussion-bubble-container {
  margin: 1rem;
  padding: 1rem;
  border-radius: .375rem;
  max-height: 600px;
  overflow: auto;
}

.discussion-header-title {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-right: 1rem;
}

.discussion-label-component {
  font-weight: bold;
  color: #B55700;
}

.discussion-input-container {
  margin: 1rem;
  padding: 1rem;
  border-radius: .375rem;
}

.discussion-comment-attachment {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: .5rem;
  border-radius: .375rem;
  background-color: #D9D9D9;
  font-size: 12px;
  gap: 5px;
  font-weight: 500;
}

.discussion-empty-state-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.discussion-emoji-container {
  position: absolute;
  margin-bottom: 22rem;
}

.comment-bubble-container {
  margin-bottom: 1.5rem;
}

.comment-bubble-header-title {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.comment-bubble-user-container {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 1rem;
}

.comment-bubble-user-name-text {
  font-weight: bold;
}

.comment-bubble-time-from-now-text {
  color: #7C7C7C;
}

.comment-bubble-edit-control-text {
  margin-top: .25rem;
  font-size: 12px;
}

.comment-bubble-attachment-container {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 1rem;
}

.comment-bubble-attachment-emoji-area {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: .5rem;
  border-radius: .375rem;
  background-color: #D9D9D9;
  font-size: 12px;
  gap: 5px;
}

.comment-bubble-attachment-item-area {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: .5rem;
  border-radius: .375rem;
  background-color: #D9D9D9;
  font-size: 12px;
  color: #000;
  gap: 5px;
  cursor: pointer;
  text-decoration: none;
}

.comment-bubble-attachment-text-edit-area {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 3px;
}

.comment-bubble-edited-text-round {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  margin-right: 8px;
}

.comment-bubble-edited-comment-text {
  color: #7C7C7C;
  font-size: 12px;
}